.App {
  text-align: center;
  background-color: lightblue;
  width: 100vw;
  height: 100vh;
}
body {
  padding: 0%;
  background-color: #080808;
  background-image: url("../public/images/BG.png");
 
}
#root{
  height: 100%;
}
#mobileimage{
  display: none;
}
.laptopimage{
  display: block;
}
.title{
 display: none;
 }
.titleinplay
{
  color: white;
  font-size: 28px;
  font-family: 'Manrope';
  text-align: justify;
  text-justify: auto;
} 
nav {
  height: 60px;
  width: 100%;
  margin: 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;
}

.keyboardClass{
  margin-top: -25px;
}

.playClass h2 {
  color: white;
  font-size: xx-large;
  width: 75%;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  margin-bottom: 5px;
  margin-top: -35px;
}
.logoClass{
  width: 180px;
  height: 35px;
}
.pyconimgClass {
  width: 35px;
  height: 35px;
}
.playClass {
  width: 100vw;
  display: flex;
  align-items: center;
  padding-top: 15px;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  
}
.boardClass {
  height: 550px;
  display: flex;
  flex-direction: column;
}
.boardClass h3 {
  color: white;
  text-align: center;
  font-family: 'Manrope', sans-serif;
}
.rowClass {
  display: flex;
  align-self: center;
  box-sizing: border-box;
}
.letterClass {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  background-color: #424242;
  margin: 5px;
  display: grid;
  place-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bolder;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}
.correctid {
  background-color: #00A7A1;
}
.almostid {
  background-color: #D5AF20;
}
.errorid {
  background-color: #939B9F;
}

.keyLine1 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin: 5px;
}
.keyLine2 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}
.keyLine3 {
  flex: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}
.keyClass {
  width: 65px;
  height: 50px;
  margin: 2px;
  border-radius: 4px;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: #424242;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}
.gameOverClass {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  text-align: center;
  flex-direction: column;
  padding-top: 10px;
  margin: 5px;
  color: white;
  font-size: large;
  word-spacing: 1.5px;
  letter-spacing: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Manrope', sans-serif;
}
.gameOverClass img {
  height: 150px;
}
.bigid {
  width: 110px;
  font-size: medium;
}
#disabledid {
  visibility: hidden;
}
.invalidClass {
  padding: 4px;
  text-align: center;
  margin-bottom: 3px;
  margin-top: 0px;
   visibility: hidden; 
  font-size: 16px;
  border-radius: 6px;
  color: "white";
  border: " 2px solid #424242";
  background-color: black;
  
}
.gameOverClass h5 {
  margin-top: -23px;
  width: 150%;
}
@media only screen and (max-width: 540px) {
  body {
    background-image: url("../public/images/BG.png");
    padding: 0;
    margin: 0;
    object-fit: cover;
    height: 100dvh;
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
  }

  .logoClass{
    width: 35px;
    height: 35px;
  }
  .laptopimage{
    display: none;
  }
  #mobileimage{
    display: block;
  }
  #root {
    height: 100%;
  }
  .wrapper {
    height: calc(100% - 60px);
  }
  .playClass {
    height: 100%;
    display: flex;
    flex-direction: column;
    object-fit: inherit;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .playClass h2 {
    width: 100%;
    padding-top: 0;
  }
  .keyboardClass {
    width : calc(100% - 20px);
    height: 200px;
    margin: 0px 8px;
  }
  .boardClass {
    width: inherit;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: inherit;
    margin-bottom: 5px;
  }
  .title{
    color: white;
    font-size: 24px;
    font-family: 'Manrope';
    display: flex;
    }
  .rowClass {
    justify-content: center;
  }
  .letterClass {
    height: 52px;
    width: 52px;
    margin: 5px;
    display: grid;
    place-items: center;
    font-size: 24px;
    font-weight: bolder;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
  }
  .gameOverClass {
    width: 175px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    align-items: center;
    justify-content: center;
    word-spacing: 1.25px;
    letter-spacing: 1px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Manrope', sans-serif;
  }
  .keyClass {
    font-size: 1.20em;
    font-weight: bold;
    border: 0;
    padding: 0;
    margin: 2 px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
  }
  .titleinplay{
    display: none;
  }
  .keyLine1,
  .keyLine2,
  .keyLine3 {
    display: flex;
    margin: 0 auto 8px;
    width: 100%;
  }
  .gameOverClass img {
    height: 150px;
  }
  .gameOverClass h4 {
    width: 150%;
  }
  .bigid {
    width: 110px;
    font-size: 18px;
  }
}